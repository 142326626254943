.view.join {
  background: $pl-grey;
  padding: 100px 0 25px;

  @media (min-width: $tablet-portrait) {
    padding: 150px 0 50px;
    min-height: calc(100vh - 180px);
  }

  .container {
    h1 {
      margin-bottom: 50px;
      text-align: center;
      font-size: 46px;
    }

    > p {
      margin-bottom: 20px;

      @media (min-width: $tablet-portrait) {
        max-width: 50%;
        font-size: 24px;
        line-height: 30px;
      }
    }

    a {
      margin-bottom: 30px;
      display: block;
      text-decoration: none;
    }

    .row {
      justify-content: center;
    }

    .pl-card {
      border: 2px solid transparent;
      border-radius: 0;
      transition: all .5s;
      padding: 30px;
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      @media (min-width: $tablet-portrait) {
        height: 100%;
      }

      img {
        display: block;
        margin: 0 auto 30px;
        height: 150px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
      }

      .btn {
        margin: 20px 0 0;
        background: $ac-dark-blue;
        border-radius: 0;
      }
    }
  }
}
