.view.dashboard {
  &.search-rfps {
    .intro {
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .filter-group {

      &:first-of-type {
        margin-top: 0;
      }
    }

    .result-item {
      p {
        margin: 0;
      }
    }
  }

  &.rfp-details {
    min-height: 100vh;

    h1, h2 {
      font-size: 24px;
    }

    .title-bar {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5;

      .partner-logo {
        width: 100px;
        margin-right: 20px;
      }

      .actions {
        .btn {
          min-width: 180px;
          margin-right: 10px;
        }
      }
    }

    section {
      margin: 20px 0;

      &.summary {
        .partner-services-card {
          margin-top: 20px;
        }
      }

      &.description {
        p {
          font-size: 16px;
          line-height: 22px;
          margin: 20px 0;
        }
      }
    }
  }
}
