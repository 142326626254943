.footer {
  background-color: $ac-dark-blue;
  color: $white;
  padding: 20px 0 35px;

  @media (min-width: $tablet-mid) {
    padding: 40px 0;
  }

  .row {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      @media (min-width: $tablet-mid) {
        justify-content: initial;
        margin: 0;
      }

      &.links {
        flex-direction: column;

        @media (min-width: $tablet-mid) {
          flex-direction: row;
          text-align: right;
          justify-content: space-between;
        }

        a {
          font-size: 14px;
        }
      }

      &.social {
        text-align: center;
        font-size: 37px;
        justify-content: center;

        a {
          margin: 0 7.5px;
        }
      }

      a {
        color: inherit;
        font-weight: normal;

        &:hover {
          color: #788EB5;
        }
      }

      .logo {
        max-height: 50px;
      }
    }
  }
}
