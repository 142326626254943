.view.dashboard {
  padding-top: 90px;

  @media(min-width: $tablet-portrait) {
    padding-top: 130px;
    //background: $pl-grey;
  }

  label {
    font-size: 18px;
  }

  .form-control {
    background: $pl-light-grey;
    border-radius: 0;

    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .intro {
    margin: 20px 0;

    @media(min-width: $tablet-portrait) {
      margin: 0 0 40px;
    }
  }

  .btn {
    color: $ac-dark-blue;
    border-color: $ac-dark-blue;

    &.btn-secondary {
      background: $ac-dark-blue;
      color: $white;
    }

    &.btn-outline-secondary {
      border: 1px solid $ac-dark-blue;

      &:hover {
        background: $ac-dark-blue;
        color: $white;
      }
    }
  }

  &.search-distributors {
    .intro {
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .search {
      margin: 0 0 40px;
    }
  }

  &.distributor-details {
    min-height: 100vh;

    h1, h2 {
      font-size: 24px;
      line-height: 28px;
    }

    .title-bar {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5;
      flex-wrap: wrap;
      justify-content: center;

      @media(min-width: $tablet-portrait) {
        flex-wrap: initial;
        justify-content: initial;
      }

      > div {
        text-align: center;

        @media(min-width: $tablet-portrait) {
          text-align: left;
        }

        h1 {
          margin: 0 0 5px;
        }

        .rating {
          font-size: 22px;
          line-height: 26px;
          font-weight: 500;
        }

        .testimonial-count {
          font-size: 14px;
          margin: 0 0 10px;
        }
      }

      .distributor-logo {
        width: 100px;
        margin-bottom: 10px;

        @media(min-width: $tablet-portrait) {
          width: 130px;
          margin-right: 20px;
        }
      }

      .rating-container {
        display: inline-flex;
        //margin-left: 20px;

        .rating {
          margin-right: 5px;
          font-weight: 600
        }

        .rating-star {
          width: 15px;
          margin-right: 2px;
        }
      }

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @media(min-width: $tablet-portrait) {
          justify-content: space-evenly;
        }

        .btn {
          min-width: 180px;
          margin-right: 10px;
          margin-bottom: 10px;

          @media(min-width: $tablet-portrait) {
            margin-bottom: 0;

          }
        }
      }
    }

    section {
      margin: 20px 0;

      &.summary {
        .partner-services-card {
          margin-top: 20px;
        }
      }

      &.description {
        p {
          font-size: 16px;
          line-height: 22px;
          margin: 20px 0;
        }
      }
    }

    .bottom-actions {
      margin: 20px 0;
    }
  }
}
