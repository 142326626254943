.main-header {
  padding: 15px 20px;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  background: #1b212a;
  color: $white;
  transition: background-color .5s;

  @media (min-width: $tablet-landscape) {
    padding: 25px 40px;
  }

  &.home {
    background: transparent;
    transition: background-color 0.5s;

    &.scrolled {
      background: rgba($black, 0.5);
    }
  }

  &.open {
    @media (max-width: $tablet-landscape - 1px) {
      background: $ac-dark-blue!important;

      .header-center {
        max-height: 200px;
        box-shadow: 4px 4px 4px rgba($black, 0.2);
        text-align: center;
      }
    }
  }

  a {
    color: inherit;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .left {
    display: flex;
    align-items: center;

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 50px;
        max-width: unset;
      }

      .desktop {
        display: none;

        @media (min-width: $tablet-landscape) {
          display: initial;
        }
      }

      .mobile {
        @media (min-width: $tablet-landscape) {
          display: none;
        }
      }
    }

    .btn-link {
      display: none;

      @media (min-width: $tablet-landscape) {
        display: initial;
      }
    }
  }

  .mobile-toggle {
    margin-left: auto;
    @media (min-width: $tablet-landscape) {
      display: none;
    }

    img {
      height: 30px;
      width: 32px;
    }
  }

  .header-center {
    position: absolute;
    width: 100vw;
    background: $ac-dark-blue;
    top: 100%;
    left: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    text-align: center;

    @media (min-width: $tablet-landscape) {
      position: relative;
      max-height: unset;
      background: transparent;
      text-align: right;
      margin-left: auto;
    }

    a {
      margin: 10px 0;
      display: block;

      @media (min-width: $tablet-landscape) {
        display: initial;
        margin: 0 15px 0 0;
      }

      @media (min-width: ($tablet-landscape + 6)) {
        margin: 0 40px 0 0;

      }
    }

    .mobile-only {
      text-align: center;
      @media (min-width: $tablet-landscape) {
        display: none;
      }

      * {
        display: block;
        margin: 20px auto;
      }
    }
  }

  .header-actions {
    display: none;

    @media (min-width: $tablet-landscape) {
      display: initial;
    }
  }

  .btn {
    max-width: 150px;
    max-height: 40px;
    line-height: 40px;
    background: $ac-beige;
    color: $ac-dark-blue;
    border: none;
    border-radius: 0;

    &:not(:disabled):not(.disabled):active {
      background: darken($ac-dark-beige, 10%);
    }
  }
}
