.btn {
  border-radius: $button-border-radius;
  border: none;
  height: $button-height;
  line-height: $button-line-height;
  font-weight: $button-font-weight;
  padding: $button-padding;
  margin: $button-margin;
  min-width: $button-min-width;

  &:focus {
    outline: none;
  }

  &.btn-rounded {
    border-radius: 65px;
  }

  &.btn-primary {
    background: $brand-primary;
    color: $white;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      background: darken($brand-primary, 20%);
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-secondary {
    background: $brand-secondary;
    color: $white;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      background: darken($brand-secondary, 20%);
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-outline-primary {
    background: $white;
    color: $brand-primary;
    border: 1px solid $brand-primary;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      border-color: transparent;
      background: $brand-primary;
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      background: $brand-primary;
      color: $white;
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-link {
    color: $pl-blue;

    &:not(:disabled):not(.disabled):focus {
      box-shadow: none;
    }
  }

  &.btn-inline {
    padding: 0;
    min-width: 0;
    margin: 0;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    display: initial;
  }
}
