.pl-card {
  padding: $card-padding;
  border-radius: $card-border-radius;
  background: $card-background;
}

.home-card {
  @extend .pl-card;
  margin-bottom: 60px;

  @media (min-width: $tablet-portrait) {
    padding-bottom: 80px;
  }

  .image-container {
    height: 100%;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
  }

  p.content {
    max-width: initial;
    margin: 0;
  }
}

.cookie-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 0;
  background: $white;
  z-index: 9999999999;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: $tablet-portrait) {
    padding: 20px 0;
  }

  .row {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      &.icon {
        display: none;

        @media (min-width: $tablet-portrait) {
          display: flex;
        }
      }
    }
  }

  img {
    max-width: 60px;
  }

  p {
    //color: $pl-grey;
    margin-bottom: 5px;

    @media (min-width: $tablet-portrait) {
      margin: 0;
    }

    a {
      //color: $pl-grey;
      text-decoration: underline;
    }
  }
}


.react-toast-notifications__container {
  padding: 30px 15px 100px!important;
  @media (min-width: $tablet-portrait) {
    padding: 30px 15px !important;
  }
}

.sales-pop {
  padding: 20px;
  border-radius: 20px;
  background: $white;
  box-shadow: 0px 10px 10px rgba(59, 104, 181, 0.4);
  color: $black;
  //margin-bottom: 40px;
}

.results-list {
  position: relative;
  min-height: 200px;


  a {
    &:hover {
      text-decoration: none;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.1);
    text-align: center;
    padding: 40px 0;
    z-index: 999;
    color: rgba($black, 0.8);
    font-weight: bold;
    font-size: 20px;
  }

  .result-item {
    padding: 20px 30px;
    background: $pl-light-grey;
    border: 1px solid #C5C5C5;
    margin-bottom: 20px;
    transition: all .5s;

    &:hover {
      background: darken($pl-light-grey, 2.5%);
      box-shadow: 0 4px 8px rgba($black, 0.2);
    }

    .result-logo {
      max-height: 60px;
      margin: 0 0 20px;
    }

    h5 {
      font-size: 18px;
      line-height: 23px;
    }

    .rating-container {
      margin: 0 0 20px;

      img {
        height: 16px;
      }
    }

    p {
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.filters-list {
  margin-bottom: 20px;

  .mobile-toggle {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    background: $light-grey;
    margin-bottom: 10px;

    @media(min-width: $tablet-portrait) {
      display: none;
    }
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: all .5s;

    &.open {
      max-height: unset;
    }

    @media(min-width: $tablet-portrait) {
      max-height: unset;
    }

    .BHG-pill-container {
      margin-top: 35px;
    }

    > div {
      @media(max-width: $tablet-portrait) {
        &:not(.filter-group) + .filter-group {
          margin: 0
        }
      }
    }

    .select-all {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
      cursor: pointer;
    }

    .location-group {
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //margin-bottom: 20px;
      }
    }

    .filter-group {
      margin-top: 40px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }

      .filter-options {
        .filter-option {
          display: flex;
          margin-bottom: 10px;
          align-content: center;
          cursor: pointer;

          .opt-checkbox {
            height: 24px;
            width: 24px;
            border: 1px solid #C5C5C5;
            margin-right: 15px;

            &.active {
              background: $ac-dark-blue;
              border-color: $ac-dark-blue;
            }
          }
        }

        .options-toggle {
          height: 35px;
          background: #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            transition: all .5s;
          }

          &.open {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.full-testimonial-details {
  .top {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    .company-name {
      display: none;
      background: $ac-dark-blue;
      color: $white;
      padding: 2.5px 10px;
      border-radius: 6px;
    }
  }

  .details {
    margin-bottom: 10px;

    .row {
      > div {
        align-items: center;
        display: flex;
      }
    }

    .col-md-7, .col-md-8 {
      margin-bottom: 10px;
    }

    .col-md-5, .col-md-4 {
      text-align: right;

      .rating-container {
        justify-content: flex-end;
        margin: 0 0 0 auto;
      }

      .value {
        margin-left: auto;
      }
    }

    .title {
      font-weight: bold;
    }

    .rating-star {
      max-width: 11px;
      margin: 0 1px;
    }
  }
}

.back-to-dash {
  padding: 20px 0;

  @media(min-width: $tablet-portrait) {
    padding: 40px 0;
  }

  span {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    svg {
      transform: rotate(90deg);
      max-width: 13px;
      margin-right: 10px;
    }
  }
}

.distributor-services-card {
  @extend .pl-card;
  padding: 35px 35px 25px;
  border-radius: $card-border-radius / 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  .detail-title {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color: $black;
    margin-bottom: 20px;
  }

  .detail-value {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    color: $black;
  }

  .no-gutters > div {
    margin-bottom: 10px;
  }

  .sect-2 .detail-value {
    margin-left: 20px;
  }
}

.awards-list {
  .award {
    padding: 20px 0;
    border-top: 1px solid rgba(247, 247, 247, 0.94);

    .award-title-bar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .name {
        font-weight: bold;
      }
    }
  }
}

.testimonials-list {
  .testimonial {
    padding: 20px 0;
    border-bottom: 1px solid rgba(247, 247, 247, 0.94);
    cursor: pointer;

    .name {
      font-weight: bold;
      display: block;
      margin: 10px 0;
    }


    .read-more {
      font-weight: bold;
    }
  }
}

.BHG-search-select {
  .BHG-search-select__control {
    background: $pl-light-grey;
    border-radius: 0;
    outline: none;
    box-shadow: none;

    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .BHG-search-select__indicators  {
    display: none;
  }

  .BHG-search-select__menu  {
    border-radius: 0;
  }
}

.BHG-pill-container {
  display: flex;
  flex-wrap: wrap;

  .BHG-pill {
    margin: 0 10px 12.5px 0;
    height: 25px;
    line-height: 25px;
    border-radius: 19px;
    padding: 0 15px;
    cursor: pointer;
    user-select: none;
    position: relative;

    .remove-icon {
      position: absolute;
      right: -3px;
      top: -6px;
      width: 15px;
    }
  }
}

.BHG-toggle {
  height: 20px;
  width: 45px;
  padding: 2px;
  border-radius: 20px;
  background: lightgray;
  cursor: pointer;
  transition: all .5s;
  margin-left: 15px;
  &.active {
    background: lightgreen;
    .slider {
      margin-left: calc(100% - 16px);
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }
  .slider {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: white;
    transition: all .5s;
    margin-left: 0;
  }
}

.rating-star {
  width: 15px;
  margin-right: 2px;
}

.rating-container {
  display: flex;
  align-content: center;

  span {
    color: $ac-dark-blue;
    margin-right: 5px;
    line-height: 1;
  }
}

//@override
.BHG-pill-container {
  .BHG-pill {
    background: $white;
    color: $brand-primary;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid $brand-primary;
    border-radius: $input-border-radius;
    margin: 0 12.5px 12.5px 0;

    &.active {
      background: $brand-primary;
      color: $white;
    }
  }
}
