.view.home {
  background: $white;
  font-family: "Montserrat";

  * {
    font-family: $poppins-font;
  }

  p {
    font-size: 16px;
  }

  .pl-card {
    box-shadow: $card-box-shadow;
    border-radius: 0;
  }

  span.btn {
    box-shadow: none;
    cursor: initial;
  }

  .btn {
    border-radius: 0;
    font-weight: 600;
    padding: 0 40px;
    min-width: unset;
    font-size: 14px;

    @media(min-width: $tablet-portrait) {
      font-size: 16px;
    }

    &.btn-primary {
      background: $ac-beige;
      color: $ac-dark-blue;

      &:not(:disabled):not(.disabled):active {
        background: darken($ac-dark-beige, 10%);
      }
    }

    &.btn-secondary {
      background: $ac-dark-blue;

      &:not(:disabled):not(.disabled):active {
        background: darken($ac-dark-blue, 20%);
      }
    }

    &.btn-tertiary {
      background: $ac-dark-beige;
      color: $white;
    }
  }

  .hero {
    background-image: linear-gradient(rgba(#1B212A, 0.5), rgba(#1B212A, 0.5)), url('//client-upload.bytehogs.com/ac/media/hero.png');
    background-position: -630px center;


    @media(min-width: $tablet-portrait) {
      padding: 120px 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    @media(min-width: $desktop-xl) {
      padding: 200px 0 160px;
      background-size: 100%;
      background-position: initial;
    }

    .hero-content {
      color: $white;
      padding: 120px 0;
      text-align: center;

      @media(min-width: $tablet-mid) {
        padding: 50px 0;
      }

      @media(min-width: $desktop-xl) {
        max-width: 55vw;
        margin: 0 auto;

        padding: 0;
      }

      * {
        color: inherit;
      }

      .btn-primary {
        color: $ac-dark-blue;
        padding: 0;
        margin-top: 15px;
      }

      h1 {
        @media(min-width: $tablet-portrait) {
          font-size: 40px;
          line-height: 60px;
        }
      }

      p, form {
        margin: 0 auto;

        @media(min-width: $tablet-portrait) {
          max-width: 75vw;
        }

        @media(min-width: $desktop-lg) {
          max-width: 43vw;
        }
      }

      p {
        margin-bottom: 25px;

        @media(min-width: $tablet-portrait) {
         margin-bottom: 20px;
        }
      }

      form {
        background: rgba($white, 0.25);
        padding: 15px;

        .input-group {
          display: flex;

          * {
            height: 45px;
            line-height: 45px;
            border-radius: 0;
          }

          input {
            margin-right: 15px;
            border: none;
            color: $black;

            &.mobile {
              @media(min-width: $tablet-portrait) {
                display: none;
              }
            }

            &.desktop {
              display: none;

              @media(min-width: $tablet-portrait) {
                display: initial;
              }
            }

            &::placeholder {
              font-size: 14px;
            }

            &:active, &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .high-level {
    .intro {
      margin: 50px auto;
      text-align: center;

      @media(min-width: $tablet-portrait) {
        max-width: 75vw;
        margin: 60px auto 40px;
      }

      @media(min-width: $tablet-landscape) {
        max-width: 50vw;
        margin: 80px auto;
      }

      h2 {
        @media(min-width: $tablet-portrait) {
          font-size: 30px;
          line-height: 45px;
        }
      }
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .pl-card {
      margin-bottom: 45px;
      padding: 0 0 30px;

      @media(min-width: $tablet-mid) {
        margin: 0;
        height: 100%;
      }

      &:hover {
        background: rgba($ac-beige, 0.4);
      }

      .img-container {
        height: 180px;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.im-1 {
          background-image: linear-gradient(rgba(27, 33, 42, 0.6),rgba(27, 33, 42, 0.6)), url('//client-upload.bytehogs.com/ac/media/hl-1.png');
        }

        &.im-2 {
          background-image: linear-gradient(rgba(27, 33, 42, 0.6),rgba(27, 33, 42, 0.6)), url('//client-upload.bytehogs.com/ac/media/hl-2.png');
        }

        &.im-3 {
          background-image: linear-gradient(rgba(27, 33, 42, 0.6),rgba(27, 33, 42, 0.6)), url('//client-upload.bytehogs.com/ac/media/hl-3.png');
        }
      }

      p {
        margin: 30px 30px 0;
        text-align: center;
      }
    }
  }

  .how-it-works {
    margin: 20px 0;

    @media(min-width: $tablet-portrait) {
      margin: 75px 0;
    }

    .intro {
      .img-container {
        position: relative;
        background: rgba($ac-beige, 0.5);
        width: 91.5%;
        margin-left: 8.5%;
        padding: 65px 0;
        margin-bottom: 50px;

        @media(min-width: $tablet-portrait) {
          margin-bottom: 0;
        }

        img {
          margin-left: -8.5%;
        }
      }

      h2 {
        font-size: 40px;
      }

      p {
        margin: 20px 0 30px;

        &:first-of-type {
          margin-bottom: 50px;
        }
      }

      .btn {
        margin-bottom: 20px;
        min-width: 230px;

        @media(min-width: $tablet-mid) {
          margin-right: 20px;
          margin-bottom: 0;
          min-width: unset;
        }
      }
    }

    .info {
      margin: 20px -15px;

      @media(min-width: $tablet-portrait) {
        margin: 45px -15px;
      }

      > div {
        margin-top: 25px;
        margin-bottom: 25px;

        @media(min-width: $tablet-mid) {
          margin-top: 45px;
          margin-bottom: 45px;
        }

        a {
          &:hover {
            text-decoration: none;
          }
        }

        .pl-card {
          padding: 0 0 20px;
          height: 100%;

          @media(min-width: $tablet-mid) {
            padding: 0 0 30px;
          }

          &:hover {
            background: rgba($ac-beige, 0.4);
          }

          &.first {
            background: $ac-dark-beige;
            text-align: center;
            padding: 80px 50px;

            @media(min-width: $tablet-portrait) and (max-width: $tablet-mid - 1px) {
              padding: 30px;
            }

            h3, p {
              color: $white;
            }

            h3 {
              font-size: 24px;
            }

            p {
              margin: 5px 0 20px;
            }
          }

          .img-container {
            height: 175px;
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media(min-width: $tablet-portrait) {
              height: 150px;
            }

            @media(min-width: $tablet-mid) {
              height: 200px;
            }

            &.im-1 {
              background-image: linear-gradient(rgba(27, 33, 42, 0.6),rgba(27, 33, 42, 0.6)), url('//client-upload.bytehogs.com/ac/media/hiw-2.png');
            }

            &.im-2 {
              background-image: linear-gradient(rgba(27, 33, 42, 0.6),rgba(27, 33, 42, 0.6)), url('//client-upload.bytehogs.com/ac/media/hiw-3.png');
            }

            &.im-3 {
              background-image: linear-gradient(rgba(27, 33, 42, 0.6),rgba(27, 33, 42, 0.6)), url('//client-upload.bytehogs.com/ac/media/hiw-4.png');
            }
          }

          p {
            margin: 20px 20px 0;
            text-align: center;

            @media(min-width: $tablet-mid) {
              margin: 50px 50px 0;
            }
          }
        }
      }
    }

  }

  .distributor-hero {
    background: #C6BEBB;
    text-align: center;
    padding: 70px 0;

    .logo {
      max-width: 250px;
    }

    h2, p {
      color: $white;
      max-width: 750px;
      margin: 0 auto;
    }

    h2 {
      font-size: 40px;
      margin: 20px auto;
    }

    .btn {
      margin-top: 20px;
    }
  }

  .distributor-steps {
    margin: 45px 0;

    .row {
      > * {
        margin-top: 25px;
        margin-bottom: 25px;

        @media(min-width: $tablet-mid) {
          margin-top: 45px;
          margin-bottom: 45px;
        }

        &:hover {
          text-decoration: none;
        }

        &.s-1, &.s-6 {
          div {
            background: $ac-dark-blue;
          }
        }

        &.s-2, &.s-4 {
          div {
            background:#C6BEBB;
          }
        }

        &.s-3, &.s-5 {
          div {
            background: $ac-beige;

            p {
              color: $ac-dark-blue;
            }
          }
        }

        img {
          width: 120%;
          max-width: calc(100% + 24px);
          margin: 0 -12px -20px;

          @media(min-width: $tablet-portrait) {
            max-width: calc(100% + 21px);
            margin: 0 -10.5px -20px;
            max-height: 490px;
          }

          @media(min-width: $tablet-mid) {
            max-height: 520px;
          }
        }

        div {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px;
          text-align: center;
          padding: 0 70px;
          height: 110px;
        }

        p {
          color: $white;
          font-weight: 600;
        }
      }
    }
  }

  .bottom-hero {
    background: $ac-beige;
    padding: 50px 0 30px;
    text-align: center;

    @media(min-width: $tablet-portrait) {
      padding: 80px 0;
    }

    h2 {
      max-width: 450px;
      margin: 0 auto 25px;
      font-size: 24px;
      line-height: 30px;

      @media(min-width: $tablet-portrait) {
        font-size: 40px;
        line-height: 60px;
      }
    }

    .btn {
      min-width: 230px;
      margin-bottom: 20px;

      @media(min-width: $tablet-portrait) {
        min-width: unset;
        margin: 0;

        &:first-of-type {
          margin-right: 30px;
        }
      }


    }
  }

/*
  .section-one {
    background-color: #1b212a;
    padding-top: 100px;

    @media (min-width: $tablet-portrait) {
      padding: 20vh 0;
    }

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-transform: capitalize;
      text-align: center;
      color: white;

      @media (min-width: $tablet-portrait) {
        font-weight: 900;
        font-size: 40px;
        line-height: 50px;
        //margin-bottom: 35px;
        width: 100%;
        text-align: left;
      }
    }

    img {
      //margin: 40px auto 100px;
      max-width: 60%;
      margin: 10px auto;

      @media (min-width: $tablet-portrait) {
        align-items: center;
        max-width: 65%;
        margin: 0 auto;
      }
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      //line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
      padding: 25px 0 35px;
      @media (min-width: $tablet-portrait) {
        text-align: left;
      }
    }

    .col-md-6 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .col-md-6:first-of-type {
      @media (max-width: $tablet-portrait - 1px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .buttons {
      text-align: center;

      @media (min-width: $tablet-portrait) {
        text-align: left;
      }
    }

    .btn {
      margin-bottom: 20px;
      color: #1b212a;
      border-color: #1b212a;

      @media (min-width: $tablet-mid) {
        margin-right: 25px;
        margin-bottom: 0;
      }

      &.btn-primary {
        background: $white;
      }
    }

    .pl-card {
      padding: 25px;
      text-align: center;
      margin: 10px 0 50px;
      border-radius: 8px;

      @media(min-width: $tablet-portrait) {
        padding: 50px;
        margin: 60px 0 0;
      }

      h3 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 30px;

        @media(min-width: $tablet-portrait) {
          margin-bottom: 50px;
        }
      }

      form {
        margin: 0 auto;

        @media(min-width: $tablet-portrait) {
          max-width: 50%;
        }

        input {
          border-radius: 8px;
        }

        .btn {
          margin-top: 15px;
          color: $white;

          @media(min-width: $tablet-portrait) {
            margin-top: 30px;
          }
        }
      }

      .btn-link {
        font-weight: normal;
        font-size: 18px;
        color: $black;
        margin-top: 10px;

        @media(min-width: $tablet-portrait) {
          margin-top: 20px;
        }
      }
    }
  }

  .section-two {
    text-align: center;
    align-items: center;
    background-color: #f9f9f9;
    //margin-top: -70px;
    padding-top: 20px;

    @media (min-width: $tablet-portrait) {
      padding-top: 70px;
    }

    .success {
      align-items: center;

      h2 {
        font-weight: bold;
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 15px;
        margin-top: 35px;
        //color: $ac-dark-grey;

        @media (min-width: $tablet-portrait) {
          font-weight: bold;
          font-size: 48px;
          line-height: 56px;
          margin-top: 70px;
          margin-bottom: 30px;
        }
      }
      p {
        text-align: center;
        font-size: 18px;

        line-height: 21px;
        margin-bottom: 45px;

        @media (min-width: $tablet-portrait) {
          text-align: center;
          font-size: 18px;
          line-height: 21px;
          max-width: 55%;
          margin: 0 auto 60px;
        }
      }
    }
    .home-card {
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 40px;
      background-color: #f9f9f9;

      .image-container {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .title {
        margin: 50px 0 20px;
        font-weight: bold;
      }

      .content {
        font-size: 18px;
        line-height: 21px;
        margin: 0;
      }
    }
  }

  .section-three {
    background-color: #ffffff;
    padding: 50px 0 10px;
    position: relative;

    @media (min-width: $tablet-mid) {
      padding: 120px 0 110px;
    }

    .works {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: $tablet-mid) {
        height: 600px;
      }

      h2 {
        color: #333333;
        margin: 0 0 40px;
        font-size: 48px;
        line-height: 56px;
      }

      p {
        color: #333333;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }

    .mission-image {
      margin-top: 50px;
      margin-left: auto;
      display: block;
      max-width: 75%;

      @media (min-width: $tablet-mid) {
        margin: 0 auto;
        //position: absolute;
        top: 120px;
        right: 0;
        max-width: 450px;
      }
    }

    .btn {
      margin-bottom: 20px;

      @media (min-width: $tablet-mid) {
        margin-right: 25px;
        margin-bottom: 0;
      }
    }
  }

  .section-four {
    img {
      max-width: 300px;
    }

    .yellow {
      padding: 50px 15px;
      background-color: lighten($brand-secondary, 25%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      @media (min-width: $tablet-portrait) {
        padding: 0;
        height: 600px;
      }

      h3 {
        font-weight: bold;
        padding-bottom: 40px;
        max-width: 100%;
        text-align: center;
        color: $white;
        margin-top: 20px;
      }

      p {
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        //padding-bottom: 40px;
        margin: 0;
        color: $white;

        @media (min-width: $tablet-portrait) {
          width: 50%;
          padding-bottom: 60px;
        }
      }

      .btn {
        min-width: 250px;
        max-height: 40px;
        line-height: 40px;
        margin-top: 40px;
        @media (min-width: $tablet-portrait) {
          margin-top: 0;
        }
      }
    }

    .coral {
      background-color: $brand-primary;
      padding: 50px 15px;
      @media (min-width: $tablet-portrait) {
        padding: 0;
        height: 600px;
      }

      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;

        @media (min-width: $tablet-portrait) {
          margin-bottom: 55px;
        }
      }

      h3 {
        padding-bottom: 20px;
        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
        }
      }

      p {
        font-size: 18px;
        line-height: 21px;

        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
          width: 48%;
        }
      }
    }

    .orange {
      background-color: #f8ecec;
      padding: 50px 15px;
      @media (min-width: $tablet-portrait) {
        padding: 0;
        height: 600px;
      }
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      img {
        padding: 30px;
        @media (min-width: $tablet-portrait) {
          margin-bottom: 80px;
        }
      }

      h3 {
        padding-bottom: 10px;
        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
        }
      }

      p {
        font-size: 18px;
        line-height: 21px;

        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
          width: 48%;
        }
      }
    }

    .blue {
      background-color: #E7D8C9;
      padding: 50px 15px;
      @media (min-width: $tablet-portrait) {
        padding: 0;
        height: 600px;
      }
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      img {
        padding: 30px;
        @media (min-width: $tablet-portrait) {
          margin-bottom: 100px;
        }
      }

      h3 {
        padding-bottom: 10px;
        color: $black;
        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
        }
      }

      p {
        color: $black;
        font-size: 18px;
        line-height: 21px;

        @media (min-width: $tablet-portrait) {
          padding-bottom: 0;
          width: 48%;
        }
      }
    }
  }

  .sectionfive {
    background-color: #f8f8f8;
    align-items: center;
    text-align: center;
    padding: 70px 0 90px;

    h2 {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 35px;
      text-align: center;

      @media (min-width: $tablet-portrait) {
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        max-width: 55%;
        margin: 0 auto 30px;
      }
    }
  }

  .sectionsix {
    padding-top: 60px;
    @media (min-width: $tablet-portrait) {
      padding-top: 110px;
    }

    h2 {
      font-size: 48px;
      margin-bottom: 25px;
      text-align: center;
      @media (min-width: $tablet-portrait) {
        //text-align: left;
      }
    }

    p {
      max-width: 100%;
      line-height: 26px;
      text-align: center;
      font-size: 18px;
      margin: 25px auto;

      @media (min-width: $tablet-portrait) {
        margin-bottom: 35px;
        max-width: 55%;
        //text-align: left;
      }
    }

    .btn {
      display: block;
      margin: 20px auto;

      @media (min-width: $tablet-portrait) {
        margin: 0 0 60px;
        display: inline-block;
      }
    }

    .home-card {
      height: calc(100% - 35px);
      text-align: center;
      border-radius: 8px;
      @media (min-width: $tablet-portrait) {
        margin: 0 85px 35px 0;
        padding-bottom: 45px;
      }
      display: flex;
      flex-direction: column;
      padding: 30px;
      box-shadow: $card-box-shadow;

      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        margin: 0 0 20px;
      }

      .title {
        font-size: 18px;
      }
    }
  }

  .bottomsec {
    background-color: #E7D8C9;
    text-align: center;
    align-items: center;
    padding: 230px 0 50px;
    margin-top: -220px;

    @media (min-width: $tablet-portrait) {
      padding: 300px 0 130px;
    }

    h2 {
      color: $black;
      font-size: 32px;
      line-height: 44px;
      font-weight: bold;
      margin: 0px 10px 30px;

      @media (min-width: $tablet-portrait) {
        font-size: 48px;
        line-height: 56px;
        font-weight: bold;
        max-width: 45%;
        margin: 0 auto 80px;
      }
    }

    .btn {
      margin-bottom: 25px;
      margin-right: 25px;

      @media (min-width: $tablet-portrait) {
        margin-bottom: 0;
      }
    }
  }*/
}
