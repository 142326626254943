//CUSTOM VARIABLES
$pl-brown: rgba(181, 129, 52, 0.26);
$pl-yellow: #ffbb54;
$pl-blue: #f9a826;
$pl-green: #2b5125;
$pl-grey: #f8f8f8;
$pl-light-grey: #FCFCFC;
$ac-pink: #E5BABF;
$ac-beige: #FEF0E7;
$ac-dark-beige: #C7BBAF;
$ac-dark-grey: #5e616b;
$ac-dark-blue: #1b212a;
$temp: #0F0F57;
$temp1: #C2C1C1;
$temp2: #E2CECA;
$temp3: #FFFFFF;
$pl-light-border: 1px solid rgba(247, 247, 247, 0.94);

$quicksand-font: "Quicksand", sans-serif;
$roboto-font: "Roboto", sans-serif;
$montserrat-font: "Montserrat", sans-serif;
$poppins-font: 'Poppins', sans-serif;

//BRAND VARIABLES
$brand-primary: $ac-pink;
$brand-secondary: $ac-dark-grey;
$brand-tertiary: 0;

//COLORS
$black: #000000;
$white: #ffffff;
$light-grey: #e5e5e5;
$lighter-grey: #d8d8d8;
$error-red: #ff7e7f;

//GLOBAL VARIABLES
$headings-font-family: $roboto-font;
$headings-font-weight: bold;
$headings-color: $black;

$paragraph-font-family: $roboto-font;
$paragraph-font-weight: 400;
$paragraph-font-size: 18px;
$paragraph-line-height: 1.5;
$paragraph-color: $black;
$paragraph-margin: 0;

$header-height: 0;
$logo-height: 0;

$button-min-width: 220px;
$button-height: 46px;
$button-font-size: 14px;
$button-font-family: $roboto-font;
$button-font-weight: bold;
$button-line-height: $button-height;
$button-border-radius: 6px;
$button-padding: 0;
$button-margin: 0;

$input-height: 40px;
$input-font-size: 16px;
$input-font-family: $roboto-font;
$input-font-weight: 400;
$input-line-height: $input-height;
$input-color: $black;
$input-background-color: rgba(247, 247, 247, 0.94);
$input-border-radius: 6px;
$input-border-color: transparent;
$input-border-width: 0;
$input-padding: 0 10px;
$input-margin: 0 0 20px;
$input-transition: box-shadow 0.55s ease-in-out;
$input-error-bs: 0px 0px 7px 1px rgba($error-red, 0.75);

$label-font-size: 14px;
$label-font-family: $roboto-font;
$label-font-weight: bold;
$label-line-height: 17px;
$label-color: $black;
$label-padding: 0;
$label-margin: 0 0 4px;

$link-color: 0;
$link-font-weight: 0;

$card-padding: 30px;
$card-border-radius: 40px;
$card-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$card-background: $white;

//RESPONSIVE
$mobile-large: 576px;
$tablet-portrait: 768px;
$tablet-mid: 992px;
$tablet-landscape: 1024px;
$desktop: 1200px;
$desktop-lg: 1400px;
$desktop-xl: 1780px;
