.view.legal {
  background: $pl-grey;
  padding: 100px 0 25px;

  @media (min-width: $tablet-portrait) {
    padding: 150px 0 50px;
    min-height: calc(100vh - 180px);
  }

  .container {
    h3 {
      margin: 10px 0;
    }

    a {
      color: $temp;
    }
  }
}
