.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
}

.modal {
  position: absolute;
  background: $white;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 20px;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.animated {
    transition: all 0.5s;
  }

  &.testimonial-details {
    max-width: 500px;
    max-height: 100%;
    padding: 20px 30px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;

    @media (min-width: $tablet-portrait) {
      max-height: 560px;
    }

    > div {
      text-align: center;

      .full-testimonial-details {
        text-align: left;
      }
    }

    .btn {
      margin: 20px auto;
      background: $ac-dark-blue;
    }
  }

  &.auth-prompt {
    max-width: 500px;
    max-height: 175px;
    padding: 20px 30px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;

    @media (min-width: $tablet-portrait) {
      //max-height: 560px;
    }

    > div {
      text-align: center;

      .full-testimonial-details {
        text-align: left;
      }
    }

    .btn {
      margin: 20px auto;
      background: $ac-dark-blue;
    }
  }

  &.contact-partner {
    max-width: 550px;
    max-height: 600px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0 0 20px;
    }

    form {
      width: 75%;
    }

    .form-error {
      margin: 10px 0 -10px;
    }

    .btn-primary {
      margin: 20px auto 0;
      display: block;
      background: $ac-dark-blue;
    }
  }
}
