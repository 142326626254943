html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: $montserrat-font;

  #root {
    height: 100%;
  }
}

img {
  max-width: 100%;
}

* {
  font-family: $montserrat-font;
}

a {
  color: $pl-blue;
  font-weight: 600;

  &:hover {
    color: darken($pl-blue, 20%);
  }
}

.view {
  position: relative;
}

.container {
  @media (min-width: 1650px) {
    max-width: 1500px;
  }
}
